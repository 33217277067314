.react-time-picker {
  width: 100%;
}

.react-time-picker__wrapper {
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.3),
    inset 0 1px 2px rgba(67, 90, 111, 0.14);
  border: none;
  border-radius: 3px;
  height: 32px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}

.react-time-picker--open > .react-time-picker__wrapper {
  outline: none;
  box-shadow: inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9,
    0 0 0 3px rgba(16, 112, 202, 0.14);
}

.react-time-picker__inputGroup > * {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  color: #425a70;
}
