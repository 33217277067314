.not-started .bar {
  fill: #91a6ae !important;
}

.in-progress .bar {
  fill: #299cd8 !important;
}

.complete .bar {
  fill: #45ab79 !important;
}

.overdue .bar {
  fill: #f44336 !important;
}

.gantt-container .popup-wrapper {
  width: 200px;
  padding-left: 8px;
  line-height: 0.6;
}
