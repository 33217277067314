.calculation {
  display: table;
}

.calculation-operation {
  display: table-row;
}

.calculation-operation .operator {
  display: table-cell;
  width: 10px;
}

.calculation-operation .symbol {
  width: 10px;
}

.calculation-operation .value {
  color: #cccccc;
  display: table-cell;
  text-align: right;
  padding-right: 1em;
}

.calculation-operation.initial .value,
.calculation-operation.initial .symbol {
  color: #5bc190;
}

.calculation-operation.total .value {
  color: #637280;
}

.calculation-operation.total .symbol,
.calculation-operation.total .value .value-content {
  border-top: 1px solid #cccccc;
}

.calculation-operation .label {
  display: table-cell;
  text-align: left;
}
