.ui.figure__header {
  font-weight: bold;
  margin-right: 1.5em;
}

.ui.figure,
.ui.figure__group {
  display: flex;
}

.ui.figure {
  margin-right: 1.5em;
}

.ui.figure__label {
  margin-right: 0.5em;
}

.ui.figure__amount-of-total .amount,
.ui.figure__amount-of-total .total {
  font-weight: bold;
}
