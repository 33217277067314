/* map font files to their appropriate styles */
/* Regular => font-weight: 100-400 */
/* Medium => font-weight: 500 */
/* Demi => font-weight: 600-700 */
/* Bold => font-weight: 800-900 */

@font-face {
  font-family: "AvenirNext";
  font-weight: 400;
  src: url("./fonts/AvenirNext-Regular.woff2") format("woff2"),
    url("./fonts/AvenirNext-Regular.woff") format("woff");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/AvenirNext-RegularItalic.woff2") format("woff2"),
    url("./fonts/AvenirNext-RegularItalic.woff") format("woff");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 500;
  src: url("./fonts/AvenirNext-Medium.woff2") format("woff2"),
    url("./fonts/AvenirNext-Medium.woff") format("woff");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 500;
  font-style: italic;
  src: url("./fonts/AvenirNext-MediumItalic.woff2") format("woff2"),
    url("./fonts/AvenirNext-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 600;
  src: url("./fonts/AvenirNext-Demi.woff2") format("woff2"),
    url("./fonts/AvenirNext-Demi.woff") format("woff");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/AvenirNext-DemiItalic.woff2") format("woff2"),
    url("./fonts/AvenirNext-DemiItalic.woff") format("woff");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 700;
  src: url("./fonts/AvenirNext-Demi.woff2") format("woff2"),
    url("./fonts/AvenirNext-Demi.woff") format("woff");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/AvenirNext-DemiItalic.woff2") format("woff2"),
    url("./fonts/AvenirNext-DemiItalic.woff") format("woff");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 800;
  src: url("./fonts/AvenirNext-Bold.woff2") format("woff2"),
    url("./fonts/AvenirNext-Bold.woff") format("woff");
}
@font-face {
  font-family: "AvenirNext";
  font-weight: 800;
  font-style: italic;
  src: url("./fonts/AvenirNext-BoldItalic.woff2") format("woff2"),
    url("./fonts/AvenirNext-BoldItalic.woff") format("woff");
}

body {
  font-family: AvenirNext, arial, sans-serif;
}

select::-ms-expand {
  display: none;
}
