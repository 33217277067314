.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.noUnderlineOnHover:hover {
  text-decoration: none;
}
